<template>
  <div class="container has-background-white-ter">

    <section id="users-timetable" class="section">
      <div class="box"
        style="align-content: flex-start; flex-direction: row; justify-content: space-between; display: flex;">

        <h4 class="title is-4 is-spaced">Student Search</h4>

        <div class="field has-addons is-pulled-right">

          {{ rows.length }}



        </div>


      </div>
      <div v-if="loaded" class="container">
        <input class="input is-primary" type="text" v-model="searchParams.Name" placeholder="Search by name" />
        <button class="button is-primary" @click="showSearchModel=!showSearchModel">More</button>
        <button class="button is-secondary" @click="testFetch()" v-if="inTest">Test</button>

        <ve-table ref="vuetable" :api-mode="false" :columns="fields" :table-data="rows"
          :event-custom-option="eventCustomOption" :max-height="450" width="100%"></ve-table>




      </div>
      <div v-else class="container notification is-primary">
        <div class="columns">
          <span class="bulma-loader-mixin"></span>
        </div>
      </div>
    </section>

    <div class="modal" v-if="showSearchModel" :class="(showSearchModel ? 'is-active' : '')">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Search Options</p>
          <button @click="showSearchModel=false" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <label class="checkbox">


            <i class="fa fa-person blue"></i>
            Male
            <input type="checkbox" value="M" v-model="searchParams.Gender" />
          </label> &nbsp;
          <label class="checkbox">
            <input type="checkbox" value="F" v-model="searchParams.Gender" />
            Female <i class="fa fa-person-dress pink"></i>
          </label>
          <hr />
          <label class="checkbox mr-2" v-for="c in classes" :key="'class-'+c">
            {{ c }}
            <input type="checkbox" :value="c" v-model="searchParams.Classes" />
          </label>
          <hr />
          <label class="checkbox mr-2" v-for="y in years" :key="'year-'+y">
            {{ y }}
            <input type="checkbox" :value="y" v-model="searchParams.Years" />
          </label>
          <hr />
          <label class="checkbox mr-2" v-for="y in houses" :key="'house-'+y">
            {{ y }}
            <input type="checkbox" :value="y" v-model="searchParams.Houses" />
          </label>

          <!-- Content ... -->
        </section>
        <footer class="modal-card-foot text-center">
          <button @click="resetSearchClass()" class="button is-danger">Reset</button>
          <button @click="showSearchModel=false" class="button is-success">OK</button>
        </footer>
      </div>
    </div>


    <StudentModal ref="myStudentModal" />

  </div>
</template>

<script>


import { structuredClone } from 'core-js/actual';
//import { getStudent } from "../../classes/student"
import StudentModal from "../../components/school/pupilmodal"

//mport Vue from 'vue'

//import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
//import VuetableFieldCheckbox from "vuetable-2/src/components/VuetableFieldCheckbox.vue"

//import { FieldsDef,  } from "helpers/fielddefs"

const ls = require('local-storage')

const searchDefaults = {

  Name: "",
  Gender: [],
  Classes: [],
  Years: [],
  Houses: [],

}




export default {
  name: "home",

  components: {
    StudentModal
  },

  data: function () {
    return {
      date: new Date(),
      loaded: false,

      virtualScrollOption: {

        enable: true,
      },

      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            click: () => {

                this.$refs.myStudentModal.showModal(
                  row.PupilID
                )
            },
            dblclick: (event) => {
              console.log("dblclick::", row, rowIndex, event);
            },
            contextmenu: (event) => {
              console.log("contextmenu::", row, rowIndex, event);
            }
          };
        },
      },

      fields: [

        {
          field: "Gender", key: "a", title: "", align: "center",
          renderBodyCell: ({ row, column, }, h) => {

            return h('i', { class: (row[column.field] == "M") ? 'fa fa-person blue' : "fa fa-person-dress pink", innerHTML: '' })


          },
        },

        { field: "FirstName", key: "b", title: "First Name", align: "right" },
        { field: "LastName", key: "c", title: "Last Name", align: "left" },
        { field: "Class", key: "d", title: "Class", align: "right" },


      ],
      //css,
      classes: [],
      years: [],
      houses: [],
      searchParams: structuredClone(searchDefaults),

      perPage: 20,
      matchingStudents: 0,
      data: [],

      sortOrder: [
        {
          field: 'LastName',
          direction: 'asc'
        }
      ],
      showSearchModel: false,

    };
  },


  watch: {

    /*searchParams: {
      handler() {
        this.dataManager([])
      },
      deep: true
    },*/
    data(newVal, oldVal) {
      console.log([newVal, oldVal])
      if (this.$refs.vuetable) {
        this.$refs.vuetable.refresh();
      }
    }
  },



  computed: {

    inTest() {
      return  (this.$getConst('runMode') != "production")
    },




    rows() {
      console.log({ data: this.data })
      if (this.data === undefined || this.data.length < 1) return [];

      let local = structuredClone(this.data);

      if (this.searchParams.Name.length) {
        local = local.filter(x => {
          if (x.FirstName.toLowerCase().indexOf(this.searchParams.Name.toLowerCase()) != -1) {
            return true;
          }
          if (x.LastName.toLowerCase().indexOf(this.searchParams.Name.toLowerCase()) != -1) {
            return true;
          }
          return false;
        })
      }

      if (this.searchParams.Gender.length === 1) {
        local = local.filter(x => {
          return this.searchParams.Gender.includes(x.Gender)
        });

      }

      const cl = this.searchParams.Classes.length

      if (cl > 0 && cl < this.classes.length) {
        local = local.filter(x => {
          return this.searchParams.Classes.includes(x.Class)
        });

      }

      const yl = this.searchParams.Years.length

      if (yl > 0 && yl < this.years.length) {
        local = local.filter(x => {
          return this.searchParams.Years.includes(x.Year)
        });

      }

      const hl = this.searchParams.Houses.length

      if (hl > 0 && hl < (this.houses?.length ?? 0)) {
        local = local.filter(x => {
          return this.searchParams.Houses.includes(x.House)
        });

      }
      // sortOrder can be empty, so we have to check for that as well

      /*
      if (sortOrder.length == 0) {
        sortOrder.push({
          sortField: 'LastName',
          direction: "asc"

        })

      }


      local.sort((a, b) => {
        let aa = a[sortOrder[0].sortField] ?? null
        let bb = b[sortOrder[0].sortField] ?? null

        if (aa == bb) {
          return 0;
        }

        if (aa < bb) {
          return (sortOrder[0].direction == "asc") ? -1 : 1
        }

        return (sortOrder[0].direction == "asc") ? 1 : -1

      })




      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log('pagination:', pagination)
      //let from = pagination.from - 1;
      //let to = from + this.perPage; */


      return local


    },



  },

  methods: {





    testFetch() {
      this.$api
      .get("v2/staff/student/daybooks/", true, true)
      .then((response) => {
        console.log(response)

      })
    },



    resetSearchClass() {
      this.searchParams = structuredClone(searchDefaults)
      this.showSearchModel = false
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
    },













  },

  async mounted() {
    this.loaded = false;
    let exp = new Date();




    let q = ls("AllStudents") ?? {}


    if (((q.students?.length ?? 0) > 0) && (q?.retrieved ?? 0) > exp.toISOString()) {
      console.log("Got from cache")
      console.log(q)
      this.loaded = true;
      this.data = q.data.students
      this.classes = Object.keys(q.data.classes).sort()
      this.years = Object.keys(q.data.years).sort()
      this.houses = Object.keys(q.data.houses).sort()

      this.resetSearchClass();
      return;
    }

    this.$api
      .get("v2/staff/student/search", true, true)
      .then((response) => {
        console.log(response)

        exp.setMinutes(exp.getMinutes() + 10);

        ls("AllStudents", { retrieved: exp, data: response })
        this.data = response.students
        this.classes = Object.keys(response.classes || {}).sort()
        this.years = Object.keys(response.years || {}).sort()
        this.houses = Object.keys(response.houses || {}).sort()
        this.resetSearchClass();
      })
      .finally(() => {
        console.log("Wivvle")
        this.loaded = true;
      });



    }

};
</script>

<style>
i.pink {
  color: pink;
}

i.blue {
  color: blue;
}

button.ui.button {
  padding: 8px 3px 8px 10px;
  margin-top: 1px;
  margin-bottom: 1px;


}

a.btn-nav.page-link.disabled {
  color: lightgray
}
</style>